import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { from, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { AuthService, RudderStack } from 'src/app/services';

@Injectable()
export class InterceptorService implements HttpInterceptor {
	constructor(
		private router: Router,
		private authService: AuthService,
		private rudderStack: RudderStack,
	) {}

	intercept(
		httpRequest: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		if (
			(this.router.url.search('/app') === 0 ||
				this.router.url.search('/setup') === 0 ||
				this.router.url.search('/activation-page') === 0) &&
			httpRequest.url !== 'https://api.cloudinary.com/v1_1/terra-do/upload'
		) {
			return from(firebase.auth().currentUser.getIdToken()).pipe(
				switchMap(token => {
					const modifiedReq = httpRequest.clone({
						setHeaders: {
							// eslint-disable-next-line @typescript-eslint/naming-convention
							Authorization: 'Bearer ' + token,
						},
					});

					return next.handle(modifiedReq).pipe(
						filter((event: HttpEvent<any>) => event.type !== 0),
						tap((event: any) => {
							if (modifiedReq.method !== 'GET') {
								if (event.body.message) {
									if (
										event.body.message
											.split(' ')
											.includes('participating', 'added')
									) {
										this.authService.addToastMsg(
											'You have successfully registered for jobfair',
										);
									} else if (
										event.body.message
											.split(' ')
											.includes('Participanting', 'deleted')
									) {
										this.authService.addToastMsg(
											'Your jobfair registration is canceled ',
										);
									} else {
										this.authService.addToastMsg(event.body.message);
									}
								}
							}
						}),
						catchError((errors: HttpErrorResponse) => {
							const { status, url, message } = errors;
							let errorMsg = '';

							switch (status) {
								case 401:
									this.authService.signOut();
									break;
								case 400:
									errorMsg = errors.error.message;
									break;
								case 404:
									console.log('This is client side error');
									errorMsg = `Error Code: ${status},  Message: ${message}`;
									break;
								case 405:
									errorMsg = `${url}, Error Code: ${status},  Message: ${message}`;
									break;
								default:
									break;
							}
							if (errors.statusText === 'Unknown Error') {
								errorMsg = `${url}, Unknown Error`;
							}
							if (errorMsg) {
								this.authService.addToastMsg(errorMsg);
							}

							this.rudderStack.log({
								type: 'API-Error',
								url: modifiedReq.urlWithParams,
								body: modifiedReq.body,
								method: modifiedReq.method,
								email: firebase.auth().currentUser?.email,
								errorMsg,
							});
							return throwError(errorMsg);
						}),
					);
				}),
			);
		} else {
			return next.handle(httpRequest);
		}
	}
}
