/* eslint-disable arrow-body-style */
import { createReducer, on } from '@ngrx/store';
import {
	interfaceApiDefaultValue,
	InterfaceAPIStatus,
} from '../../../model/loader';

import * as NgrxLearningActions from '../actions/ngrx.actions';

export const ngrxFeatureKey = 'ngrx';

export const ngrxInitialState: any = {};

export const ngrxReducer = createReducer(
	ngrxInitialState,
	on(NgrxLearningActions.loadInit, (state, action) => {
		let oldState = { ...state };

		let defaultValue = {
			...interfaceApiDefaultValue,
			filter: {},
		};

		if (!oldState[`${action.service}_${action.args.join('_')}`]) {
			oldState[`${action.service}_${action.args.join('_')}`] = defaultValue;
		}

		return oldState;
	}),
	on(NgrxLearningActions.loadInitData, (state, action) => {
		return {
			...state,
			[`${action.service}_${action.args.join('_')}`]: {
				...state[`${action.service}_${action.args.join('_')}`],
				data: action.data,
				apiStatus: InterfaceAPIStatus.end,
			},
		};
	}),
	on(NgrxLearningActions.loadMoreDataPagination, (state, action) => {
		return {
			...state,
			[`${action.service}_${action.args.join('_')}`]: {
				...state[`${action.service}_${action.args.join('_')}`],
				data: [
					...state[`${action.service}_${action.args.join('_')}`].data,
					...action.data,
				],
				apiStatus: InterfaceAPIStatus.loadMoreEnd,
			},
		};
	}),
	on(NgrxLearningActions.loadInitDataPagination, (state, action) => {
		return {
			...state,
			[`${action.service}_${action.args.join('_')}`]: {
				...state[`${action.service}_${action.args.join('_')}`],
				data: action.data,
				apiStatus: InterfaceAPIStatus.end,
				pageRecord: action.pageRecord,
			},
		};
	}),
	on(NgrxLearningActions.loadAPIStatus, (state, action) => {
		if (action.status === InterfaceAPIStatus.start) {
			return {
				...state,
				[`${action.service}_${action.args.join('_')}`]: {
					...state[`${action.service}_${action.args.join('_')}`],
					data: [],
					apiStatus: action.status,
				},
			};
		} else {
			return {
				...state,
				[`${action.service}_${action.args.join('_')}`]: {
					...state[`${action.service}_${action.args.join('_')}`],
					apiStatus: action.status,
				},
			};
		}
	}),
	on(NgrxLearningActions.setFilters, (state, action) => {
		let oldFilters = state[`${action.service}_${action.args.join('_')}`].filter;

		Object.keys(action.filter).filter(keys => {
			if (action.filter[keys] === null) {
				delete oldFilters[keys];
			} else {
				oldFilters[keys] = action.filter[keys];
			}
		});

		return {
			...state,
			[`${action.service}_${action.args.join('_')}`]: {
				...state[`${action.service}_${action.args.join('_')}`],
				filter: oldFilters,
			},
		};
	}),

	on(NgrxLearningActions.updateInitData, (state, action) => {
		let temp = state[`${action.service}_${action.args.join('_')}`].data;

		temp = temp.map(data => {
			if (data[action.dataId] == action.id) {
				if (data[action.key] !== undefined) {
					data[action.key] = action.value;
				}
			}
			return data;
		});
		return {
			...state,
			[`${action.service}_${action.args.join('_')}`]: {
				...state[`${action.service}_${action.args.join('_')}`],
				data: temp,
			},
		};
	}),
);
