import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-image-modal',
	templateUrl: './image-modal.component.html',
	styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent implements OnInit {
	@Input() title: any;
	@Input() path: any;

	constructor(public modalController: ModalController) {
		//
	}

	ngOnInit() {
		//
	}

	async closeModal() {
		await this.modalController.dismiss();
	}
}
