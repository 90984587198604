import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { from } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class SignUpService {
	constructor(
		private auth: AngularFireAuth,
		public afStore: AngularFirestore,
	) {}

	public googleInit(subscribedToNewsletter) {
		return from(
			this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()),
		)
			.pipe(
				map(res => res.user),
				tap((user: any) => {
					this.setUserData(user, user.displayName, subscribedToNewsletter);
				}),
				shareReplay(),
			)
			.toPromise();
	}

	public init(name, email, password, subscribedToNewsletter) {
		return from(this.auth.createUserWithEmailAndPassword(email, password))
			.pipe(
				tap((res: any) => {
					res.user.updateProfile({ displayName: name });
				}),
				tap(res => {
					this.setUserData(res.user, name, subscribedToNewsletter);
				}),
				shareReplay(),
			)
			.toPromise();
	}

	public setUserData(user6, name, checked) {
		// const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${user.uid}`);
		const userData = {
			uid: user6.uid,
			email: user6.email,
			displayName: name || user6.displayName,
			photoURL: user6.photoURL,
			emailVerified: user6.emailVerified,
			timeStamp: new Date().getTime() + '',
		};

		if (checked) userData['newsletter'] = 'newsletter';

		const urlParams = new URLSearchParams(window.location.search);
		const redirectUrl = urlParams.get('redirectUrl');
		if (redirectUrl != null) {
			const redirectUrlParams = new URLSearchParams(
				'?' + redirectUrl.split('?')[1],
			);
			const medium = redirectUrlParams.get('utm_medium') || '';
			const source = redirectUrlParams.get('utm_source') || '';
			const campaign = redirectUrlParams.get('utm_campaign') || '';
			userData['utmParams'] = medium + '_' + source + '_' + campaign;
		}

		// TODO: May be move to firestore service
		return from(
			this.afStore
				.collection('users')
				.doc(user6.uid)
				.set(userData, { merge: true }),
		);
	}
}
