import { createReducer, on } from '@ngrx/store';
import {
	InterfaceAPI,
	interfaceApiDefaultValue,
	InterfaceAPIStatus,
} from 'src/app/model/loader';

import * as SelectionBoxActions from './selection-box.actions';

export const selectionBoxFeatureKey = 'selection-box';

export type State = InterfaceAPI;

export const initialState: State = {
	...interfaceApiDefaultValue,
};

export const reducer = createReducer(
	initialState,

	on(SelectionBoxActions.loadSelectionBox, (state): State => state),
	on(
		SelectionBoxActions.loadSelectionBoxSuccess,
		(state, action): State => ({
			...state,
			data: action.data,
			apiStatus: InterfaceAPIStatus.end,
		}),
	),
	on(
		SelectionBoxActions.loadSelectionBoxApiStatus,
		(state, action): State => {
			if (action.status === InterfaceAPIStatus.start) {
				return {
					...state,
					data: [],
					apiStatus: action.status,
				};
			} else {
				return {
					...state,
					apiStatus: action.status,
				};
			}
		},
	),
);
