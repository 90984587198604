import { createAction, props } from '@ngrx/store';
import { InterfaceAPIStatus } from '../../../model/loader';

export const loadInit = createAction(
	'init call',
	props<{ service: string; args: any[] }>(),
);

export const loadInitData = createAction(
	'load init data',
	props<{ service: string; args: any[]; data: any[] }>(),
);

export const loadMoreDataPagination = createAction(
	'load more data',
	props<{ service: string; args: any[]; data: any[] }>(),
);

export const loadInitDataPagination = createAction(
	'load init data pagination',
	props<{ service: string; args: any[]; data: any[]; pageRecord: number }>(),
);

export const loadAPIStatus = createAction(
	'load API status',
	props<{ service: string; args: any[]; status: InterfaceAPIStatus }>(),
);

export const setFilters = createAction(
	'set filters',
	props<{ service: string; args: any[]; filter: any }>(),
);

export const updateInitData = createAction(
	'update init data',
	props<{
		service: string;
		args: any[];
		id: any;
		key: any;
		value: any;
		dataId: any;
	}>(),
);
