import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageModalComponent } from './image-modal/image-modal.component';

@Directive({
	selector: 'img[default]',
	host: {
		'(error)': 'updateUrl()',
		'(load)': 'load()',
		'[src]': 'src',
	},
})
export class ImagePreloadDirective {
	@Input() src: string;
	@Input() default: string;
	@Input() title: string;
	@Input() shouldOpen = false;

	@HostBinding('class.cursor') get cursorPointer() {
		return this.shouldOpen;
	}

	@HostListener('click', ['$event.target'])
	async openImageModal() {
		if (this.shouldOpen) {
			const modal = await this.modalController.create({
				component: ImageModalComponent,
				cssClass: 'image-modal',
				backdropDismiss: false,
				componentProps: {
					title: this.title,
					path: this.src,
				},
			});

			modal.onDidDismiss();

			return await modal.present();
		}
		return;
	}

	constructor(public modalController: ModalController) {}

	updateUrl() {
		this.src = this.default;
	}
	load() {}
}
