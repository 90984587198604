import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'search',
})
export class SearchPipe implements PipeTransform {
	transform(value: any[], search: string) {
		if (search) {
			return value.filter(
				({ name }) =>
					String(name).toLowerCase().search(search.toLowerCase()) > -1,
			);
		} else {
			return value;
		}
	}
}
