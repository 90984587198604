import { NgModule } from '@angular/core';
import {
	AngularFireAuthGuard,
	redirectLoggedInTo,
	redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const redirectUnauthorizedToLogin = () =>
	redirectUnauthorizedTo(['/auth/login']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['/app']);

const routes: Routes = [
	{
		path: '',
		redirectTo: 'auth/sign-up',
		pathMatch: 'full',
	},
	{
		path: 'auth',
		loadChildren: () =>
			import('./pages/auth/auth.module').then(m => m.AuthPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectLoggedInToItems },
	},
	{
		path: 'app',
		loadChildren: () =>
			import('./pages/app/app.module').then(m => m.AppPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: 'setup',
		loadChildren: () =>
			import('./pages/setup/setup.module').then(m => m.SetupPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: 'payment/:id',
		loadChildren: () =>
			import('./pages/payment/payment.module').then(m => m.PaymentPageModule),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: 'activation-page',
		loadChildren: () =>
			import('./pages/activation-page/activation-page.module').then(
				m => m.ActivationPagePageModule,
			),
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: 'auth/logout',
		loadChildren: () =>
			import('./pages/logout/logout.module').then(m => m.LogoutPageModule),
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
