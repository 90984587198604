import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { TooltipDirective } from './tooltip.directive';

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule],
	declarations: [TooltipDirective],
	exports: [TooltipDirective],
})
export class TooltipDirectiveModule {}
