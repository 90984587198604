import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
@Injectable({
	providedIn: 'root',
})
export class FormService {
	constructor() {
		// block
	}
	public markFormGroupTouched(formGroup: FormGroup) {
		Object.values(formGroup.controls).forEach(control => {
			control.markAsTouched();
			control.markAsDirty();
		});
	}

	// return list of error messages
	public validationMessages() {
		const messages = {
			required: 'This field is required',
			email: 'This email address is invalid',
			invalidCharacters: (matches: any[]) => {
				let matchedCharacters = matches;

				matchedCharacters = matchedCharacters.reduce(
					(characterString, character, index) => {
						let str = characterString;
						str += character;

						if (matchedCharacters.length !== index + 1) {
							str += ', ';
						}

						return str;
					},
					'',
				);

				return `These characters are not allowed: ${matchedCharacters}`;
			},
		};

		return messages;
	}

	public validateForm(
		formToValidate: FormGroup,
		formErrors: any,
		checkDirty?: boolean,
	) {
		const form = formToValidate;

		for (const field in formErrors) {
			if (field) {
				formErrors[field] = '';
				const control = form.get(field);
				const messages = this.validationMessages();
				if (control && !control.valid) {
					if (!checkDirty || control.touched) {
						for (const key in control.errors) {
							if (key && key !== 'invalidCharacters') {
								formErrors[field] = formErrors[field] || messages[key];
							} else {
								formErrors[field] =
									formErrors[field] || messages[key](control.errors[key]);
							}
						}
					}
				}
			}
		}
		return formErrors;
	}
}
