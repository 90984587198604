import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ImageModalModule } from './image-modal/image-modal.module';

import { ImagePreloadDirective } from './image-preload.directive';

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, ImageModalModule],
	declarations: [ImagePreloadDirective],
	exports: [ImagePreloadDirective],
})
export class ImagePreloadDirectiveModule {}
