import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';
import * as SelectionBoxActions from './redux/selection-box.actions';
import { selectSearchFiltersWithText } from './redux/selection-box.selectors';

@Component({
	selector: 'app-selection-box',
	templateUrl: './selection-box.component.html',
	styleUrls: ['./selection-box.component.scss'],
})
export class SelectionBoxComponent implements OnInit {
	@Input() name = '';
	@Input() value = '';
	@Input() data = [];
	@Input('placeholder') placeholder = '';
	@Input('type') type: 'all' | 'id' | 'value' = 'id';
	@Input('dataOnly') dataOnly = false;

	search = '';

	searchActive = false;
	list: any = [];

	constructor(
		public popoverController: PopoverController,
		private store: Store,
	) {
		if (!this.dataOnly) {
			this.store.dispatch(SelectionBoxActions.loadSelectionBox());
		}
	}

	ngOnInit(): void {
		if (!(this.data && this.data.length > 0)) {
			this.store
				.select(selectSearchFiltersWithText(this.name))
				.pipe(
					filter(data => data.length > 0),
					first(),
				)
				.subscribe(data => {
					this.list = [...data].map(item => ({
						...item,
						active: this.value.includes(item.id),
					}));
				});
		} else {
			this.list = [...this.data].map((item: any) => ({
				...item,
				active: this.value.includes(item.id),
			}));
		}
	}

	close() {
		this.popoverController.dismiss();
	}

	done() {
		if (this.type === 'id') {
			this.popoverController.dismiss({
				data: this.list.filter(({ active }) => active).map(({ id }) => id),
			});
		} else if (this.type === 'all') {
			this.popoverController.dismiss({
				data: this.list.filter(({ active }) => active),
			});
		}
	}
}
