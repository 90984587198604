import {
	Directive,
	EventEmitter,
	HostListener,
	Input,
	Output,
} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { SelectionBoxComponent } from './selection-box/selection-box.component';

@Directive({
	selector: '[appSelection]',
})
export class SelectionDirective {
	@Input('name') name = '';
	@Input('value') value = [];
	@Input('dataOnly') dataOnly = false;
	@Input('placeholder') placeholder = '';
	@Input('type') type: 'all' | 'id' | 'value' = 'id';
	@Input('data') data: any;

	@HostListener('click') onClick() {
		this.openSelection();
	}

	@Output() learnClick = new EventEmitter();

	constructor(public popoverController: PopoverController) {}

	async openSelection() {
		const modal = await this.popoverController.create({
			component: SelectionBoxComponent,
			cssClass: 'selection',
			componentProps: {
				name: this.name,
				value: this.value,
				placeholder: this.placeholder,
				type: this.type,
				data: this.data,
				dataOnly: this.dataOnly,
			},
		});

		modal.style.cssText = `--max-width: 540px;--min-width: 540px;`;

		await modal.present();

		const { data } = await modal.onDidDismiss();

		if (!!data && data.data) {
			this.learnClick.emit(data.data);
			console.log(data.data);
		}
	}
}
