import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SelectionBoxEffects } from './selection-box/redux/selection-box.effects';
import * as fromSelectionBox from './selection-box/redux/selection-box.reducer';
import { SelectionBoxComponent } from './selection-box/selection-box.component';
import { SelectionBoxService } from './selection-box/service/selection-box.service';
import { SelectionDirective } from './selection.directive';
import { SearchPipe } from './selection-box/pipe/search.pipe';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		StoreModule.forFeature(
			fromSelectionBox.selectionBoxFeatureKey,
			fromSelectionBox.reducer,
		),
		EffectsModule.forFeature([SelectionBoxEffects]),
	],
	declarations: [SelectionDirective, SelectionBoxComponent, SearchPipe],
	exports: [SelectionDirective],
	providers: [SelectionBoxService],
})
export class SelectionDirectiveModule {}
