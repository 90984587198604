import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class TimezoneService {
	public timezone;
	constructor() {
		this.timezone = this.timeZoneAbbreviated();
	}

	timeZoneAbbreviated = () => {
		const { 1: tz } = new Date().toString().match(/\((.+)\)/);

		// In Chrome browser, new Date().toString() is
		// "Thu Aug 06 2020 16:21:38 GMT+0530 (India Standard Time)"

		// In Safari browser, new Date().toString() is
		// "Thu Aug 06 2020 16:24:03 GMT+0530 (IST)"

		if (tz.includes(' ')) {
			return tz
				.split(' ')
				.map(([first]) => first)
				.join('');
		} else {
			return tz;
		}
	};
}
