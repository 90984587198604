export const environment = {
	production: true,
	firebaseConfig: {
		apiKey: 'AIzaSyAit0M9mMKuY3oVcXF-IrFFnvy3ZfliS3w',
		authDomain: 'hubble-159a2.firebaseapp.com',
		databaseURL:
			'https://hubble-159a2-default-rtdb.asia-southeast1.firebasedatabase.app',
		projectId: 'hubble-159a2',
		storageBucket: 'hubble-159a2.appspot.com',
		messagingSenderId: '302493094096',
		appId: '1:302493094096:web:afba897677aa13aa6d8ed5',
		measurementId: 'G-2JMXMHWMQ5',
	},
	url: {
		profile: 'https://terra-api.terra.do/dev/profile',
		hubble: 'https://terra-api.terra.do/dev/hubble',
		cohort: 'https://terra-api.terra.do/dev/cohortdev',
		class: 'https://api.terra.do/stage/classdev',
		company: 'https://terra-api.terra.do/dev/portfoliodev',
		catalog: 'https://terra-api.terra.do/dev/catalogdev',
		payment: 'https://terra-api.terra.do/dev/payment',
		firebaseToken:
			'https://us-central1-hubble-159a2.cloudfunctions.net/minttoken',
	},
	singUpLink: {
		step1: 'tn8jqU8A',
		step2: 'test_dR616md5ugQp64w8Au',
		step3: 'tnqOQmWP',
	},
};
