import { Injectable } from '@angular/core';
import BrowserInfo from '@smartbear/browser-info';
import firebase from 'firebase/compat/app';

@Injectable({
	providedIn: 'root',
})
export class RudderStack {
	public sessionId = '';
	public email: any = '';

	public os: any = {};

	constructor() {
		this.init();
		BrowserInfo.detect();

		this.os = {
			name: BrowserInfo.name,
			release: BrowserInfo.release,
			version: BrowserInfo.version,
			os: BrowserInfo.os,
			agent: navigator.userAgent,
		};
	}

	public init() {
		this.sessionId = this.getUUID();
		this.email = firebase.auth().currentUser?.email;
	}

	public identify(email, messageJSON, cb = () => {}) {
		this.init();
		window['rudderanalytics'].identify(email, messageJSON, () => {
			// block
			console.log('identify call', email, messageJSON);

			cb();
		});
	}

	public reset(cb = () => {}) {
		window['rudderanalytics'].reset(true);
	}

	public log(messageJSON, cb = () => {}) {
		messageJSON['app'] = 'EPP';
		messageJSON['email'] = this.email;
		messageJSON['sessionId'] = this.sessionId;
		messageJSON['clientTimestamp'] = new Date().getTime();
		messageJSON['page'] = location.pathname;

		messageJSON['t_os'] = this.os;
		messageJSON['web_version'] = 'web_2.0';

		window['rudderanalytics'].track(messageJSON.type, messageJSON, () => {
			// block

			cb();
		});
	}

	public getUUID() {
		const length = 16;
		let result = '';
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}
}
