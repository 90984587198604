import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
	dsn:
		'https://cf010b7914d24abbbc1ccaa48f11e298@o916632.ingest.sentry.io/6747101',
	integrations: [
		new BrowserTracing({
			tracingOrigins: ['localhost', 'https://yourserver.io/api'],
			routingInstrumentation: Sentry.routingInstrumentation,
		}),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.2,
});

enableProdMode();
platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then(success => console.log(`Bootstrap success`))
	.catch(err => console.error(err));
