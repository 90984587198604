/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { CookieService } from 'ngx-cookie-service';
import { Logout } from 'src/app/reducers';
import { environment } from 'src/environments/environment';
import { RudderStack } from '../rudderstack';
import { error } from './errors';
import { SignUpService } from './sign-up/sign-up.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	error: string[] = [];

	constructor(
		private authFire: AngularFireAuth,
		private signUpService: SignUpService,
		private router: Router,
		public ngZone: NgZone,
		private store: Store,
		private cookieService: CookieService,
		private rudderStack: RudderStack,
		public afStore: AngularFirestore,
	) {}

	earthTokenExists() {
		if (this.cookieService.get('earth_token')) {
			const token = this.cookieService.get('earth_token');

			this.loginWithCustomToken(token);
		}
	}

	loginWithCustomToken(token, bool = false) {
		this.authFire
			.setPersistence('local')
			.then(() => this.authFire.signInWithCustomToken(token))
			.then(res => {
				if (bool) {
					this.fetchAndSetToken(res.user);
				}
				this.afterLogin();
			})
			.catch(e => this.showError(e));
	}

	removeToastMsg(str) {
		this.error = this.error.filter(err => err !== str);
	}

	addToastMsg(str, timeout = 3) {
		this.error.push(str);

		setTimeout(() => {
			this.removeToastMsg(str);
		}, timeout * 1000);
	}

	public signIn(email: string, password: string) {
		return this.authFire
			.signInWithEmailAndPassword(email, password)
			.then(u2 => {
				this.log(100, 'login', 'password');

				this.fetchAndSetToken(u2.user);

				this.addToastMsg('Redirecting to home page!', 1);
				this.afterLogin();
			})
			.catch(e => this.showError(e));
	}

	public googleSignIn() {
		this.authFire
			.signInWithPopup(new firebase.auth.GoogleAuthProvider())
			.then((res: any) => {
				this.afStore
					.doc(`users/${res.user.uid}`)
					.get()
					.subscribe(async (data: any) => {
						if (data.data() === undefined) {
							await this.signUpService.setUserData(
								res.user,
								res.user.name,
								true,
							);
						}

						this.fetchAndSetToken(res.user);

						this.log(100, 'login', 'google');

						this.afterLogin();
					});
			})
			.catch(e => this.showError(e));
	}

	afterLogin() {
		const redirectTo = localStorage.getItem('redirectTo');
		this.rudderStack.identify(firebase.auth().currentUser?.email, null);

		Sentry.setUser({ email: firebase.auth().currentUser?.email });

		if (redirectTo) {
			localStorage.removeItem('redirectTo');
			if (redirectTo === '/auth/login') {
				this.router.navigate(['app']);
			} else {
				this.router.navigate([redirectTo]);
			}
		} else {
			this.router.navigate(['app']);
		}
	}

	public signUp(name, email, password, subscribedToNewsletter) {
		return this.signUpService
			.init(name, email, password, subscribedToNewsletter)
			.then(() => {
				this.log(-1, 'signup', 'password');

				this.afterLogin();
			})
			.catch(e => this.showError(e));
	}

	public googleSignUp(subscribedToNewsletter) {
		return this.signUpService
			.googleInit(subscribedToNewsletter)
			.then(() => {
				this.log(-1, 'signup', 'google');

				this.afterLogin();
			})
			.catch(e => this.showError(e));
	}

	public signOut() {
		return this.authFire
			.signOut()
			.then(() => {
				Sentry.setUser(null);

				this.log(-1, 'logout', 'password');

				this.addToastMsg('Redirecting to login page!', 1);

				this.rudderStack.reset();
				this.cookieService.delete('earth_token', '/', '.terra.do');
				this.ngZone.run(() => {
					this.router.navigate(['/auth/login'], { replaceUrl: true });
					// eslint-disable-next-line @ngrx/prefer-action-creator-in-dispatch
					this.store.dispatch(new Logout());
				});
			})
			.catch(e => this.showError(e));
	}

	public redirectAuth(
		url: 'forgot-password' | 'forgot-email' | 'sign-up' | 'login',
	) {
		this.router.navigateByUrl(`/auth/${url}`, { skipLocationChange: true });
	}

	public resetPassword(email) {
		return this.authFire
			.sendPasswordResetEmail(email)
			.then(() => {
				this.log(-1, 'reset', 'password');

				this.addToastMsg(
					'We have sent a link to ' + email + ' to reset your password.',
				);

				this.router.navigate(['/auth/login']);
			})
			.catch(e => this.showError(e));
	}

	private showError(e: any) {
		const index = error.findIndex(x => x.code === e.code);

		if (index !== -1) {
			const err: any = error[index];

			if (err.logout) {
				this.signOut();
			}
			this.addToastMsg(err.message);
		} else {
			this.addToastMsg('Oops, something went wrong!');
		}

		throw new Error('error');
	}

	public fetchAndSetToken(user3: any) {
		fetch(environment.url.firebaseToken + `?uid=${user3.uid}`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				this.setCookie('earth_token', data.token);
			});
	}

	public setCookie(name: string, val: string) {
		const date = new Date();
		const value = val;

		// Set it expire in 7 days
		date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

		// Set it
		this.cookieService.set(name, value, date, '/', '.terra.do');
		/*this.window.document.cookie =
			name +
			'=' +
			value +
			';expires=' +
			date.toUTCString() +
			';path=/;domain=.terra.do;';*/
	}

	log(event, type, provider) {
		this.rudderStack.log({
			event,
			type,
			provider,
		});
	}
}
