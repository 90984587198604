import { createAction, props } from '@ngrx/store';
import { InterfaceAPIStatus } from 'src/app/model/loader';

export const loadSelectionBox = createAction(
	'[SelectionBox] Load SelectionBox',
);

export const loadSelectionBoxSuccess = createAction(
	'[SelectionBox] Load SelectionBox Success',
	props<{ data: any }>(),
);

export const loadSelectionBoxApiStatus = createAction(
	'[SelectionBox] Load SelectionBox API Status',
	props<{ status: InterfaceAPIStatus }>(),
);
